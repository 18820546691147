import React, { Component } from 'react';
import Image from "../image"

class PortfolioEntry extends Component {
  render() {
    return <div className="contentful-entry portfolio-entry is-clearfix">
      <div className="portfolio-entry__image is-pulled-left">
        <Image url={this.props.image.image.file.url} alt={this.props.image.altText} />
      </div>
      <h3 className="title is-size-6 is-size-7-mobile">{this.props.title}</h3>
      <a href={this.props.link} >Dummy Link Text</a>
      <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
      <hr />
    </div>
  }
}

export default PortfolioEntry;