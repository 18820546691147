import React from "react"
import Layout from "../components/layout/layout"
// import Hero from "../components/layout/hero"
// import Navbar from "../components/layout/navbar"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import PortfolioEntry from "../components/portfolio/portfolio-entry"
import Hero from "../components/layout/hero"
import Navbar from "../components/layout/navbar"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
  <Layout>
    <SEO
      title="Portfolio of previous work | Caledonian Tree Services"
      canonicalUrl={data.site.siteMetadata.siteUrl + "/contact-success/"}
      description=""
    />
    <Hero
      hero={data.hero.desktopImage.fluid}
      heroMobile={data.heroMobile.mobileImage.fluid}
      logo={data.logo.childImageSharp.fixed}
      arb={data.arb.childImageSharp.fixed}
      strapline="Scotland’s Premier Arboriculture & <br /> Commercial Tree Surgeon"
      straplineMobile="Scotland’s Premier Arboriculture<br /> & Commercial Tree Surgeon"/>
    <Navbar/>
    <section className="section">
      <div className="container content-container">
      {data.allContentfulPortfolio.edges.map(({ node }, index) => (
        <PortfolioEntry key={index} image={node.image} title={node.title} link={node.link} text={node.text.childMarkdownRemark.html} />
      ))}
      </div>
    </section>
  </Layout>
  )
}

export const query = graphql`
  {
   allContentfulPortfolio(sort: { fields: [order], order: ASC }) {
    edges{
      node{
        title
        link
        text{
          childMarkdownRemark{
            html
          }
        }
        image{
          image{
            file{
              url
            }
          }
          altText
        }
      }
    }
  }
  hero: contentfulHeroImage(desktopImage: {title: {eq: "portfolio-hero"}}) {
    desktopImage{
      fluid{
        src
      }
    }
  }
  heroMobile: contentfulHeroImage(mobileImage: {title: {eq: "portfolio-hero-mobile"}}) {
    mobileImage {
      fluid {
        src
      }
    }
  }
  logo: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fixed(width: 150, height: 150) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
  arb: file(relativePath: { eq: "ARB-Approved2.png" }) {
    childImageSharp {
      fixed(width: 125, height: 150) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
}
`
